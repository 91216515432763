.services-view-wrap {
}

.services-view {
}

.services-view__main-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - 78px);
  margin-bottom: 100px;
}

.services-view__main-text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.services-view__main-text {
  max-width: 380px;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0.96px;
}

.services-view__main-sub-text {
  max-width: 500px;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.44px;
  color: var(--greyColor2);
}

.services-view__img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.services-view__text-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 100px;
}

.services-view__text-title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}

.services-view__text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.22px;
  color: var(--greyColor3);
}

@media (min-width: 640px) {
  .services-view__main-text {
    max-width: 455px;
    font-size: 59px;
    line-height: 91px;
  }
  .services-view__text-wrap {
    flex-direction: row;
    justify-content: space-between;
  }
  .services-view__text-title {
    font-size: 37px;
    font-weight: 700;
    max-width: 480px;
  }
  .services-view__text {
    font-size: 16px;
    max-width: 66%;
    line-height: normal;
    letter-spacing: 0.4px;
  }
}

@media (min-width: 960px) {
  .services-view__main-text {
    max-width: 600px;
    font-size: 79px;
    line-height: 120px;
  }
  .services-view__main-sub-text {
    max-width: 840px;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0.6px;
  }
  .services-view__text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .services-view__text-wrap {
    margin-bottom: 200px;
  }
}

@media (min-width: 1200px) {
  .services-view__main-text {
    max-width: 875px;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .services-view__text-title {
    font-size: 48px;
  }
  .services-view__text-wrap {
    margin-bottom: 240px;
  }
}
