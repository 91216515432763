.accordion-filters-wrap {
  /* margin-top: 45px; */
  border: 2px solid #fff;
  padding: 8px;
  /* min-height: 75px; */
}

.accordion-filters {
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.56px;
  transition: 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-filters__title {
  font-size: 24px;
}

.symbol {
  font-weight: bold;
  margin-left: 5px;
  color: #ccc;
  transition: transform 0.4s ease;
}

.accordion-filters__active .symbol {
  transform: rotate(180deg);
}

.accordion-jobs-wrap__active {
  /* padding-bottom: 15px;  */
}

.accordion-filters .symbol {
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-weight: 400;
  color: #ccc;
}

.accordion-filters__panel {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  max-height: 0;
  overflow: hidden;
  color: #fff;
  transition: max-height 0.6s ease-out;
}

.accordion-filters__item {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.accordion-filters__checkbox {
  display: none;
}

.accordion-filters__custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid white;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.accordion-filters__custom-checkbox::after {
  content: " ";
  font-size: 18px;
  color: white;
  font-weight: bold;
  line-height: 1;
}

.accordion-filters__checkbox:checked
  + .accordion-filters__custom-checkbox::after {
  content: "+";
  color: white;
}

@media (min-width: 640px) {
  .accordion-filters {
    font-size: 36px;
  }
  .accordion-filters__panel {
    font-size: 14px;
  }
}

@media (min-width: 960px) {
  .accordion-filters {
    font-size: 32px;
  }
  .accordion-filters__panel {
    font-size: 20px;
  }
  .accordion-filters__title {
    font-size: 32px;
  }
}
