.details-view__main-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100dvh - 78px);
  margin-bottom: 100px;
}

.details-view__main-text-wrap {
}

.details-view__main-text {
  max-width: 380px;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: 1.2px;
  text-align: left;
}

.details-btn-wrap {
  width: 100%;
  min-height: 96px;
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
}

.share-btn-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.share-btn {
  width: 100%;
  height: 56px;
  border: 1px solid #fff;
  font-weight: 700;
  font-size: 28px;
}

.share-social-wrap {
  width: 45%;
  height: 56px;
  border: 1px solid #fff;
  border-top: none;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  visibility: hidden;
}

.share-social-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.share-social-wrap.show {
  visibility: visible;
}

.apply-btn {
  width: 100%;
  height: 56px;
  background-color: #fff;
  color: #000;
  font-weight: 700;
  font-size: 28px;
}

.details-view__section {
  margin-top: 150px;
}

.details-view__section-title {
  color: rgba(255, 33, 28, 1);
  margin-bottom: 30px;
}

.details-view__list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.details-view__list li {
  line-height: 1.3;
}

@media (min-width: 640px) {
  .details-view__main-text {
    max-width: 455px;
    font-size: 59px;
    line-height: 91px;
  }
  .details-btn-wrap {
    flex-direction: row;
  }
  .share-btn {
    font-size: 38px;
    height: 66px;
  }
  .apply-btn {
    font-size: 38px;
    height: 66px;
  }
  .share-social-wrap {
    height: 66px;
  }
  .share-social-icon {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 960px) {
  .details-view__main-text {
    max-width: 600px;
    font-size: 79px;
    line-height: 120px;
  }
}

@media (min-width: 1200px) {
  .details-view__main-text {
    max-width: 875px;
    font-size: 100px;
    line-height: normal;
  }
  .share-btn {
    font-size: 48px;
    height: 96px;
  }
  .apply-btn {
    font-size: 48px;
    height: 96px;
  }
  .share-social-wrap {
    padding: 15px;
    height: 96px;
  }
  .share-social-icon {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 1440px) {
  .details-view__main-text {
    font-size: 120px;
  }
}
