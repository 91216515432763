.blog-view-wrap {
  padding-top: 115px;
}

.blog-view {
  padding-bottom: 80px;
}

.blog-view__main-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
}

.blog-view__main-text-wrap {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  gap: 10px;
  padding: 0 80px;
}

.blog-view__main-text {
  max-width: 380px;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0.96px;
  text-align: left;
}

.blog-view__main-img {
  width: 100%;
  min-height: 210px;
}

.blog-view__date {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #828282;
  margin-top: 15px;
  margin-bottom: 20px;
}

.blog-view__text {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  letter-spacing: 0.22px;
  color: var(--greyColor3);
  white-space: pre-wrap;
  margin: 20px 0;
}

.blog-view__list--circle {
  list-style-type: circle;
  margin-left: 20px;
}

.blog-view__text--padding {
  padding-left: 20px;
}

.blog-view__title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
  margin-top: 55px;
  margin-bottom: 15px;
}

.blog-view__img {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.blog-view_red-text {
  background-color: #ff211c99;
  color: #fff;
}

.blog-view__code-wrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 40px;
  color: var(--greyColor3);
  position: relative;
}

.blog-view__code-block {
  width: 100%;
  margin-bottom: 20px;
}

.blog-view__btn {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
}

.blog-view__btn i {
  padding-right: 5px;
}

.blog-view__copy-notification {
  position: fixed;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.15);
  color: var(--greyColor3);
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  z-index: 1000;
  transition: bottom 0.5s ease-in-out;
}

.blog-view__copy-notification.show {
  bottom: 20px;
}

@media (min-width: 640px) {
  .blog-view__main-text {
    font-size: 42px;
    max-width: none;
  }
  .blog-view__title {
    font-size: 32px;
    line-height: normal;
  }
  .blog-view__text {
    font-size: 16px;
    line-height: 1.4;
  }
  .blog-view__btn {
    right: 20px;
  }
}

@media (min-width: 960px) {
  .blog-view__main-text {
    font-size: 48px;
  }
  .blog-view__title {
    font-size: 40px;
  }
  .blog-view__text {
    font-size: 20px;
  }
}

.blog-view__block {
  white-space: pre-wrap;
}
