.custom-select {
  position: relative;
  width: 100%;
  height: 40px;
  border: 2px solid #fff;
  padding-left: 15px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  box-sizing: border-box;
}

.custom-select__selected {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
}

.custom-select__arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  transition: transform 0.2s ease;
}

.custom-select__arrow.open {
  transform: rotate(180deg);
}

.custom-select__dropdown {
  position: absolute;
  top: 100%;
  left: -2px;
  width: calc(100% + 4px);
  border: 2px solid #fff;
  border-top: none;
  background-color: var(--darkColor);
  z-index: 10;
  box-sizing: border-box;
}

.custom-select__dropdown li {
  list-style: none;
}

.custom-select__option {
  padding: 10px;
  cursor: pointer;
}

.custom-select__option:hover {
  background: #eee;
  color: #000;
}

@media (min-width: 640px) {
  .custom-select {
    height: 56px;
    font-size: 32px;
  }
}
