.response-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.response-modal__content {
  background: var(--darkColor);
  padding: 20px;
  border: 1px solid var(--lightColor);
  text-align: center;
  min-width: 400px;
}

.response-modal__text {
  font-size: 18px;
  margin-bottom: 20px;
}

.response-modal__button {
  width: 100%;
  border: 1px solid var(--lightColor);
  background: var(--darkColor);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 0;
}

.response-modal__img {
  width: 176px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
