.steck-popover {
    display: flex;
    align-items: center;
    justify-content: center;
}

.steck-popover__btn {

}

.steck-popover__btn-img {
    display: block;
    width: auto;
    height: fit-content;
    max-height: 60px;
}

.steck-popover__popover-wrap {
    display: inline-block;
    min-height: 150px;
}

.steck-popover__popover-text {
    color: #fff;
    background: var(--darkColor);
    padding: 2px;
}

.steck-popover__popover-wrap .MuiPaper-root {
    background: transparent;
}