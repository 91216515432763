.filter_menu-wrap {
}

.filter_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  gap: 30px;
}

.filter_menu-title {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  top: 120px;
  left: 20px;
}

.filter_menu-close-icon {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 120px;
  right: 20px;
  cursor: pointer;
}

.filter_menu-btn {
  height: 40px;
  background-color: rgba(254, 254, 254, 1);
  color: #000;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

@media (min-width: 640px) {
}

@media (min-width: 960px) {
}

@media (min-width: 1200px) {
}
