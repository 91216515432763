.possibilities-view-wrap {
}

.possibilities-view {
}

.possibilities-view__main-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - 78px);
  margin-bottom: 100px;
}

.possibilities-view__main-text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.possibilities-view__main-text {
  max-width: 380px;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: 1.2px;
}

.possibilities-view__main-sub-text {
  max-width: 500px;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.44px;
  color: var(--greyColor2);
}

.possibilities-view__jobs-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 100px;
}

.possibilities-view__jobs {
  width: 100%;
  display: none;
  gap: 30px;
}

.possibilities-view__jobs-mobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.possibilities-view__jobs-input-container {
  height: 50px;
  position: relative;
}

.possibilities-view__jobs-input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.possibilities-view__jobs-input-close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.possibilities-view__jobs-input {
  padding: 5px 0 5px 46px;
  width: 100%;
  height: 50px;
  border: 2px solid #fff;
  font-size: 24px;
}

.possibilities-view__jobs-input::placeholder {
  font-weight: 700;
}

.possibilities-view__jobs-title-wrap {
  display: flex;
  gap: 10px;
}

.possibilities-view__jobs-title {
  text-align: end;
  font-size: 24px;
}

.possibilities-view__jobs-filter-icon {
  width: 32px;
  height: 32px;
}

.possibilities-view__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.possibilities-view__list-item {
  width: 100%;
}

.possibilities-view__list-item-title-wrap {
  width: 100%;
  min-height: 106px;
  display: flex;
  padding: 10px;
  border: 2px solid #fff;
  cursor: pointer;
}

.possibilities-view__list-item-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0%;
}

.possibilities-view__list-item-text-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 0 10px;
}

@media (min-width: 640px) {
  .possibilities-view__main-text {
    max-width: 455px;
    font-size: 59px;
    line-height: 91px;
  }
  .possibilities-view__jobs-wrap {
    flex-direction: row;
  }
  .possibilities-view__jobs {
    display: flex;
    width: 50%;
    flex-direction: column;
  }
  .possibilities-view__list {
    width: 50%;
  }
  .possibilities-view__jobs-filter-icon {
    display: none;
  }
  .possibilities-view__jobs-input-container {
    width: 100%;
    height: 60px;
    position: relative;
  }
  .possibilities-view__jobs-input {
    display: block;
  }
  .possibilities-view__jobs-mobile {
    display: none;
  }
}

@media (min-width: 960px) {
  .possibilities-view__main-text {
    max-width: 600px;
    font-size: 79px;
    line-height: 120px;
  }
  .possibilities-view__main-sub-text {
    max-width: 840px;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0.6px;
  }
  .possibilities-view__jobs-input-container {
    height: 60px;
  }
  .possibilities-view__jobs-input {
    font-size: 32px;
    height: 60px;
  }
  .possibilities-view__jobs-title {
    font-size: 32px;
  }
  .possibilities-view__list-item-title {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .possibilities-view__main-text {
    max-width: 875px;
    font-size: 100px;
    line-height: normal;
  }
}

@media (min-width: 1440px) {
  .possibilities-view__main-text {
    font-size: 120px;
  }
}
