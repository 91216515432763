.apply-form-wrap {
  margin-top: 100px;
  margin-bottom: 100px;
}

.apply-form {
  gap: 20px;
  width: 100%;
}

.apply-form__text-wrap {
  display: flex;
  gap: 20px;
}

.apply-form__title {
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0.64px;
  color: #fff;
  margin-bottom: 50px;
}

.apply-form__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.4px;
  color: #fff;
  margin-bottom: 10px;
}

.apply-form__input {
  width: 100%;
  height: 40px;
  border: 2px solid #fff;
  margin-bottom: 30px;
  padding-left: 15px;
  font-size: 14px;
}

.apply-form__input-file {
  display: none;
}

.apply-form__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: 2px solid #fff;
  margin-bottom: 30px;
  padding: 0 15px;
  font-size: 14px;
  color: #888;
  cursor: pointer;
  position: relative;
}

.apply-form__file-name {
  color: #fff;
}

.apply-form__add-file-icon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.apply-form__textarea {
  width: 100%;
  min-height: 200px;
  border: 2px solid #fff;
  resize: none;
  background-color: transparent;
  outline: none;
  color: #fff;
  font-size: 14px;
  margin-bottom: 30px;
  padding: 10px 15px;
  font-family: "Unbounded", sans-serif;
}

.apply-form-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.apply-form-checkbox__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.apply-form-checkbox__label {
  width: 30px;
  height: 30px;
  border: 2px solid white;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.apply-form-checkbox__label::after {
  content: " ";
  font-size: 25px;
  color: white;
  font-weight: bold;
  line-height: 1;
}

.apply-form-checkbox__input:checked + .apply-form-checkbox__label::after {
  content: "+";
  color: white;
}

.apply-form-checkbox__text a {
  text-decoration: underline;
}

.apply-form-checkbox__text {
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.2px;
  color: #fff;
  width: 100%;
}

.error-message {
  color: red;
}

.apply-form-btn {
  width: 100%;
  height: 56px;
  background-color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.4px;
  color: #000;
  margin-top: 70px;
}

@media (min-width: 640px) {
  .apply-form__text {
    font-size: 20px;
  }
  .apply-form__input {
    font-size: 30px;
    height: 56px;
  }
  .apply-form__label {
    font-size: 30px;
    height: 56px;
  }
  .apply-form__add-file-icon {
    width: 26px;
    height: 26px;
  }
  .apply-form__textarea {
    font-size: 30px;
  }
}
